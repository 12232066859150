import ajax from "@/utils/ajax.js";

/////////////////////退款////////////////////
/**
 * 获取退款列表
 * @param {*} params
 * @returns
 */
export function getRefundList(params) {
  return ajax.post("/PostSalesRefund/getList", params);
}


/**
 * 获取退款详情
 * @param {*} params
 * @returns
 */
export function getRefundDetail(params) {
  return ajax.post("/PostSalesRefund/detail", params);
}

/**
 * 申请退款
 * @param {*} params
 * @returns
 */
export function applyRefund(params) {
  return ajax.post("/PostSalesRefund/applyRefund", params);
}

/**
 * 审核退款：通过/拒绝
 * @param {*} params
 * @returns
 */
export function auditRefund(params) {
  return ajax.post("/PostSalesRefund/audit", params);
}

/**
 * 修改 退款备注等
 * @param {*} params
 * @returns
 */
export function updateRefund(params) {
  return ajax.post("/PostSalesRefund/update", params);
}


/**************************** 钱包退余额 ******************************/
/**
 * 列表
 * @param {*} params
 * @returns
 */
export function getWalletRefundList(params) {
  return ajax.post("/CustomerServiceVipCardRefund/getList", params);
}

/**
 * 详情
 * @param {*} params
 * @returns
 */
export function getWalletRefundDetail(params) {
  return ajax.post("/CustomerServiceVipCardRefund/detail", params);
}

/**
 * 查看可退余额
 * @param {*} params
 * @returns
 */
export function getWalletRefundAmount(params) {
  return ajax.post("/CustomerServiceVipCardRefund/getReturnBalance", params);
}

/**
 * 审核
 * @param {*} params
 * @returns
 */
export function auditWalletRefund(params) {
  return ajax.post("/CustomerServiceVipCardRefund/audit", params);
}

/**
 * 修改
 * @param {*} params
 * @returns
 */
export function updateWalletRefund(params) {
  return ajax.post("/CustomerServiceVipCardRefund/update", params);
}
